<template>
    <v-card v-if="tacReasons">
        <v-toolbar color="primary" dark>Antrag zur Prüfung einer Ruhezeitverlängerung</v-toolbar>
        <v-stepper flat v-model="e1">
            <v-stepper-header>
                <v-stepper-step :complete="e1 > 1" step="1">Kundeninformationen</v-stepper-step>

                <v-divider></v-divider>
                <v-stepper-step :complete="e1 > 2" step="2">Timestop</v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step :complete="e1 > 3" step="3">Abschluss</v-stepper-step>
            </v-stepper-header>
            <v-stepper-items>
                <v-stepper-content step="1">
                    <v-card color="transparent" elevation="0">
                        <v-row no-gutters>
                            <v-col class="pb-0" cols="12">
                                <v-select
                                    :disabled="viewOnly"
                                    label="Anrede"
                                    :items="[
                                        { name: 'Herr', value: 'm' },
                                        { name: 'Frau', value: 'w' },
                                    ]"
                                    item-value="value"
                                    item-text="name"
                                    class="my-0 py-0"
                                    v-model="customerInfos.gender"
                                ></v-select>
                            </v-col>

                            <v-col class="py-0" cols="6">
                                <v-text-field
                                    :disabled="viewOnly"
                                    label="Vorname"
                                    class="my-0 py-0"
                                    v-model="customerInfos.firstName"
                                ></v-text-field>
                            </v-col>
                            <v-col class="py-0" cols="6">
                                <v-text-field
                                    :disabled="viewOnly"
                                    label="Nachname"
                                    class="my-0 py-0"
                                    v-model="customerInfos.lastName"
                                ></v-text-field>
                            </v-col>
                            <v-col class="py-0" cols="6">
                                <v-text-field
                                    :disabled="viewOnly"
                                    label="Geburtstag"
                                    type="date"
                                    class="my-0 py-0"
                                    v-model="customerInfos.birthday"
                                ></v-text-field>
                            </v-col>
                            <v-col class="py-0" cols="6">
                                <v-text-field
                                    :disabled="viewOnly"
                                    label="Telefon"
                                    class="my-0 py-0"
                                    v-model="customerInfos.phone"
                                ></v-text-field>
                            </v-col>

                            <v-col class="py-0" cols="12">
                                <v-text-field
                                    :disabled="viewOnly"
                                    label="Strasse"
                                    class="my-0 py-0"
                                    v-model="customerInfos.street"
                                ></v-text-field>
                            </v-col>

                            <v-col class="pt-0" cols="6">
                                <v-text-field
                                    :disabled="viewOnly"
                                    type="number"
                                    label="Postleitzahl"
                                    class="my-0 py-0"
                                    v-model="customerInfos.zip"
                                ></v-text-field>
                            </v-col>
                            <v-col class="pt-0" cols="6">
                                <v-text-field
                                    :disabled="viewOnly"
                                    label="Stadt"
                                    class="my-0 py-0"
                                    v-model="customerInfos.city"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-alert type="info">Bitte überprüfe deine Daten</v-alert>
                            </v-col>
                        </v-row>
                        <v-card-actions class="justify-space-between">
                            <v-btn @click="$emit('close')" text>Abbrechen</v-btn>
                            <!---  :disabled=" !customerInfos.selection || !creatorInfos.selection" -->
                            <v-btn color="primary" :loading="loadingTACContract" @click="confirmUserInfos()">
                                Weiter
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-stepper-content>
                <!-- 

    Mind 1 Monat
    maximal 6 Monate

    halbjahres Vertrag 1/12
    Jahres Vertrag 1/24

    Rückwirkend erlaubt
    NUr in aktueller Laufzeit

    Preis ist nur abo + zusatzpakete (KEINE CHIPS KEINE EINSCHREIBEGEBÜHR)

    2 Gleichzeitig beantragen ja

    nur 2 Jahre zurück

    Immer der günstigste Preis nehmen

    Während bonusperiode kann ich keinen Timestop erfassen

-->

                <v-stepper-content class="fill-height" step="2">
                    <v-card class="mb-12 fill-height" color="transparent" elevation="0">
                        <v-col cols="4">
                            <v-card-title>Kunden Vertragsinformationen</v-card-title>
                            <v-menu
                                v-model="contractStartDatePicker"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                min-width="auto"
                                offset-y
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="selectedContract.start"
                                        label="Startdatum (Vertrag)"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    locale="de-CH"
                                    v-model="selectedContract.start"
                                    @input="contractStartDatePicker = false"
                                    @change="checkAboDuration()"
                                ></v-date-picker>
                            </v-menu>
                            <v-menu
                                v-model="contractEndDatePicker"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                min-width="auto"
                                offset-y
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="selectedContract.end"
                                        label="Enddatum (Vertrag)"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    locale="de-CH"
                                    v-model="selectedContract.end"
                                    @input="contractEndDatePicker = false"
                                    @change="checkAboDuration()"
                                ></v-date-picker>
                            </v-menu>
                            <v-text-field
                                :disabled="viewOnly"
                                type="number"
                                prepend-icon="mdi-cash"
                                label="Abopreis in Franken"
                                suffix=".-"
                                class="my-0 py-0"
                                v-model="selectedContract.aboPrice"
                            ></v-text-field>
                            <v-select
                                v-model="selectedContract.aboType"
                                label="Abo Typ"
                                prepend-icon="mdi-file-document"
                                :items="['Halbjahres Abo', 'Jahres Abo', '2 Jahres Abo', '3 Jahres Abo']"
                            ></v-select>
                        </v-col>
                        <v-divider></v-divider>
                        <v-col
                            class="pb-0"
                            cols="4"
                            v-if="selectedContract.start && selectedContract.end && selectedContract.aboPrice"
                        >
                            <v-card-title>Timestop Informationen</v-card-title>
                            <v-menu
                                v-model="startDatePicker"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                min-width="auto"
                                offset-y
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="timestopInfos.start"
                                        label="Start"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    locale="de-CH"
                                    :min="$moment(selectedContract.start, 'YYYY-MM-DD').format()"
                                    :max="calcStartPickerMaxDate"
                                    v-model="timestopInfos.start"
                                    @change="timestopInfos.end = null"
                                    @input="startDatePicker = false"
                                ></v-date-picker>
                            </v-menu>

                            <v-menu
                                v-model="endDatePicker"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                min-width="auto"
                                offset-y
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="timestopInfos.end"
                                        label="Ende"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        :disabled="!timestopInfos.start"
                                        v-bind="attrs"
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    locale="de-CH"
                                    :min="
                                        $moment(timestopInfos.start, 'YYYY-MM-DD')
                                            .add('months', 1)
                                            .subtract('days', 1)
                                            .format()
                                    "
                                    :max="calcEndPickerMaxDate"
                                    v-model="timestopInfos.end"
                                    @input="endDatePicker = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="12" v-if="timestopInfos.start && timestopInfos.end">
                            <v-alert type="info">
                                Dauer des Unterbruchs:
                                {{
                                    $moment(timestopInfos.end, 'YYYY-MM-DD').diff(
                                        $moment(timestopInfos.start, 'YYYY-MM-DD'),
                                        'days'
                                    ) + 1
                                }}
                                Tage
                                <div v-if="showPrices">
                                    <br />
                                    Geschätzte Gebühren:
                                    <br />
                                    Mit Attest:
                                    {{ getPriceWithAttest }} CHF
                                    <br />
                                    Ohne Attest:
                                    {{ getPriceWithoutAttest }} CHF
                                </div>
                            </v-alert>
                        </v-col>
                        <v-col v-if="tacReasons && tacReasons.length" class="py-0" cols="12">
                            <v-select
                                mandatory
                                :items="
                                    tacReasons.Reasons.Reason.map((reason) => {
                                        return { text: reason.ReasonCode, value: reason.ReasonID.UniqueID }
                                    })
                                "
                                @change="changeTimestopReason"
                                v-model="timestopInfos.tacReason"
                                label="Grund des Unterbruchs"
                                required
                            ></v-select>
                        </v-col>
                        <v-row v-if="withAttest">
                            <v-col cols="12">
                                <v-row class="upload-section">
                                    <v-col cols="12" class="mb-0">
                                        <v-alert type="info">
                                            Lade bitte eine Bescheinigung hoch, um deinen Antrag auf Unterbrechung der
                                            Mitgliedschaft zu vervollständigen. Die Informationen in deinem Anhang
                                            müssen mit dem angegebenen Zeitraum übereinstimmen.
                                        </v-alert>
                                        <v-file-input
                                            multiple
                                            @change="uploadImagesTimestops"
                                            label="Bilder und Dokumente auswählen"
                                        ></v-file-input>
                                    </v-col>
                                </v-row>

                                <v-row v-if="timestopInfos.gallery && timestopInfos.gallery.length > 0">
                                    <v-col cols="12">
                                        <v-window v-model="activeIndexTimestops">
                                            <v-window-item
                                                v-for="(image, index) in timestopInfos.gallery"
                                                :key="`card-${index}`"
                                            >
                                                <v-card color="transparent" height="200">
                                                    <v-row class="fill-height" align="center" justify="center">
                                                        <v-col align="center" cols="12">
                                                            <span class="font-weight-bold">
                                                                {{ image.title }}
                                                            </span>
                                                            <v-icon
                                                                v-if="
                                                                    image.title.includes('.pdf') ||
                                                                    image.title.includes('.PDF')
                                                                "
                                                                x-large
                                                            >
                                                                mdi-file-pdf-box
                                                            </v-icon>
                                                            <v-img
                                                                contain
                                                                class="mx-auto"
                                                                v-else-if="image.dataUrl"
                                                                height="150px"
                                                                max-width="200px"
                                                                :src="image.dataUrl"
                                                                alt="Uploaded Image"
                                                            ></v-img>
                                                        </v-col>
                                                    </v-row>
                                                </v-card>
                                            </v-window-item>
                                        </v-window>
                                        <v-card-actions class="justify-center">
                                            <v-item-group v-model="activeIndexTimestops" class="text-center" mandatory>
                                                <v-item
                                                    v-for="n in timestopInfos.gallery.length"
                                                    :key="`btn-${n}`"
                                                    v-slot="{ active, toggle }"
                                                >
                                                    <v-btn :input-value="active" icon @click="toggle">
                                                        <v-icon>mdi-record</v-icon>
                                                    </v-btn>
                                                </v-item>
                                            </v-item-group>
                                        </v-card-actions>
                                    </v-col>
                                </v-row>

                                <!-- Gallery Section -->
                                <v-row v-if="false" class="gallery-section">
                                    <v-col cols="12">
                                        <div
                                            ref="scrollContainer"
                                            @scroll="updateActiveIndexPackages"
                                            style="
                                                overflow-x: scroll;
                                                white-space: nowrap;
                                                scroll-snap-type: x mandatory;
                                            "
                                        >
                                            <v-card
                                                elevation="0"
                                                transparent
                                                v-for="(image, index) in timestopInfos.gallery"
                                                :key="index"
                                                ref="cards"
                                                style="display: inline-block; scroll-snap-align: center; width: 100%"
                                            >
                                                <v-img
                                                    contain
                                                    max-height="200px"
                                                    :src="'/api/' + image.src"
                                                    alt="Uploaded Image"
                                                ></v-img>

                                                <v-card-actions>
                                                    <v-btn
                                                        class="mx-auto"
                                                        @click="deleteImageTimestops(index)"
                                                        color="error"
                                                    >
                                                        Löschen
                                                    </v-btn>
                                                </v-card-actions>
                                            </v-card>
                                        </div>
                                    </v-col>
                                    <v-col class="mb-5" cols="12" style="text-align: center">
                                        <span
                                            v-for="(image, index) in timestopInfos.gallery"
                                            :key="'dot-' + index"
                                            @click="scrollToIndexPackages(index)"
                                            :class="['dot', { active: activeIndexPackages === index }]"
                                        ></span>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-card-actions class="justify-space-between">
                            <v-btn text @click="e1--">Zurück</v-btn>

                            <v-btn
                                :loading="checkingCheckins"
                                color="primary"
                                :disabled="
                                    !timestopInfos.start ||
                                    !timestopInfos.end ||
                                    (withAttest && !timestopInfos.gallery.length) ||
                                    !timestopInfos.tacReason
                                "
                                @click="checkCheckins()"
                            >
                                weiter
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-stepper-content>

                <v-stepper-content step="3">
                    <v-card class="mb-12" color="transparent" elevation="0">
                        <v-card-title>Bedingungen</v-card-title>
                        <v-expansion-panels class="mt-1">
                            <v-expansion-panel>
                                <v-expansion-panel-header>1. Interne Prüfung</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    Mit einer Genehmigung der Geschäftsleitung der well come FIT AG kann die
                                    Mitgliedschaft unterbrochen werden. Der Antrag kann jederzeit abgelehnt werden.
                                </v-expansion-panel-content>
                            </v-expansion-panel>

                            <v-expansion-panel>
                                <v-expansion-panel-header>2. Dauer des Unterbruchs</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    Der Unterbruch muss mindestens einen Monat betragen und darf sechs Monate nicht
                                    überschreiten.
                                </v-expansion-panel-content>
                            </v-expansion-panel>

                            <v-expansion-panel>
                                <v-expansion-panel-header>3. Unterlagen</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    Dem Antrag sind Unterlagen zur Prüfung beizulegen. Eine einmalige Gebühr ist bei
                                    Vorlage eines ärztlichen Attests, eines Marschbefehls oder eines Nachweises für
                                    einen Sprachaufenthalt zu entrichten.
                                </v-expansion-panel-content>
                            </v-expansion-panel>

                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    4. Zahlungspflicht während des Unterbruchs
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    Ein Unterbruch befreit nicht von der Zahlungspflicht sowie der automatischen
                                    Abonnementerneuerung und wird nach Bezahlung der Unterbruchgebühr der aktuellen
                                    Laufzeit angerechnet.
                                </v-expansion-panel-content>
                            </v-expansion-panel>

                            <v-expansion-panel>
                                <v-expansion-panel-header>5. Höhe der anfallenden Gebühr</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    Die Gebühr beträgt beim Jahresabonnement 1/24 und beim Halbjahresabonnement 1/12 des
                                    Mitgliederbeitrages pro Monat Unterbruch.
                                </v-expansion-panel-content>
                            </v-expansion-panel>

                            <v-expansion-panel>
                                <v-expansion-panel-header>6. Maximal zwei Unterbrüche</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    Während der Abonnementsdauer sind maximal zwei Unterbrüche möglich.
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                        <v-card-text>Mit meiner Unterschrift akzeptiere ich die Bedingungen.</v-card-text>

                        <signature-pad
                            class="mx-auto"
                            :signature-data="customerInfos.signature"
                            @startDrawing="signature = true"
                            @resetDrawing="resetSignature"
                            @saveSignature="saveSignature"
                            ref="signatureConditions"
                        ></signature-pad>
                    </v-card>

                    <v-btn text @click="e1--">Zurück</v-btn>
                    <v-btn color="primary" @click="saveEntry()">Speichern</v-btn>
                </v-stepper-content>
            </v-stepper-items>
        </v-stepper>
    </v-card>
</template>

<script>
import FormWizard from '../../../components/vueStepWizard/FormWizard.vue'
import userSearch from '../../../components/userSearch.vue'
import signaturePad from '@/components/signaturePad/signaturePad.vue'
import api from '@/api'
import { mapState } from 'vuex'
export default {
    components: { FormWizard, userSearch, signaturePad },
    data() {
        return {
            profileID: null,
            fixTermTimesMissingBefore: [],
            fixTermTimesMissingAfter: [],
            dividePriceBy: false,
            activeIndexTimestops: 0,
            signature: false,
            timestopText: '',
            withAttest: false,
            e1: 1,
            timestopInfos: {
                gallery: [],
                start: null,
                end: null,
                tacReason: null,
                duration: null,
                durationSelection: null,
                fee: null,
                timeAndPlace: '',
            },
            customerInfos: {
                selection: null,
                signature: null,
            },
            existingTimestops: [],
            selectedFile: null,
            uploadStatus: '',
            imageSources: {},
            timestopFormId: null,
            loadingTACContract: false,
            contractEndDate: null,
            contractStartDate: null,
            contractStartDatePicker: false,
            contractEndDatePicker: false,
            startDatePicker: false,
            endDatePicker: false,
            selectedContract: {
                start: null,
                end: null,
                aboPrice: null,
            },
            contractInformations: null,
            allTransactions: [],
            checkins: [],
            checkingCheckins: false,
            allIdlePeriods: [],
            tacReasons: null,
        }
    },
    async created() {
        try {
            let allTACReasons = await api.getTACReasons(1)

            allTACReasons.Reasons.Reason = allTACReasons.Reasons.Reason.filter((reason) => reason.ValidTimes)
            this.tacReasons = allTACReasons
            /*if (this.tacReasons) {
                        this.timestopInfos.tacReason = this.tacReasons.Reasons.Reason[0].ReasonID.UniqueID
                    }*/
        } catch (e) {
            this.$toast.error('Fehler beim Laden der Timestop Gründe.')
        }
    },
    methods: {
        checkAboDuration() {
            let type = ''
            if (this.selectedContract.start && this.selectedContract.end) {
                if (
                    this.$moment(this.selectedContract.end).diff(this.$moment(this.selectedContract.start), 'months') >
                    11
                ) {
                    type = 'Jahres Abo'
                }
                if (
                    this.$moment(this.selectedContract.end).diff(this.$moment(this.selectedContract.start), 'months') >
                    23
                ) {
                    type = '2 Jahres Abo'
                }
                if (
                    this.$moment(this.selectedContract.end).diff(this.$moment(this.selectedContract.start), 'months') >
                    35
                ) {
                    type = '3 Jahres Abo'
                }

                this.selectedContract.aboType = type ? type : 'Halbjahres Abo'
            }
        },
        changeTimestopReason(selectedReason) {
            let selectedVal = this.tacReasons.Reasons.Reason.find(
                (reason) => reason.ReasonID.UniqueID === selectedReason
            ).ReasonCode
            this.withAttest =
                selectedVal.toLowerCase().includes('arzt') ||
                selectedVal.toLowerCase().includes('schwanger') ||
                selectedVal.toLowerCase().includes('milit') ||
                selectedVal.toLowerCase().includes('sprachauf')
        },
        setContractActive(contract) {
            if (contract.idlePeriods.length >= 2) {
                return alert('Du hast in dieser Laufzeit bereits die maximale Anzahl von 2 Timestops.')
            }
            this.selectedContract = contract
            this.timestopInfos = {
                gallery: [],
                start: null,
                end: null,
                tacReason: null,
                duration: null,
                durationSelection: null,
                fee: null,
                timeAndPlace: '',
            }
            this.withAttest = false
            this.e1 = 3
        },
        async checkCheckins() {
            this.checkingCheckins = true
            this.checkins = await api.fetchCheckinsPerTAC({
                MemberInfo: {
                    MemberNumber: this.contractInformations.MemberNumber,
                },
                TimeSpan: {
                    number: 0,
                    Start: this.$moment(this.timestopInfos.start, 'YYYY-MM-DD').add('days', 1).format(),
                    End: this.$moment(this.timestopInfos.end, 'YYYY-MM-DD').format(),
                },
            })
            if (this.checkins && this.checkins.length) {
                alert(
                    'Du kannst keinen Timestop für den Gewünschten Zeitraum erstellen, da du dich am ' +
                        this.$moment(this.checkins[0]).format('YYYY-MM-DD') +
                        ' eingecheckt hast.\nAnzahl Checkins im gewählten Zeitraum: ' +
                        this.checkins.length
                )
            } else {
                for (let idlePeriod of this.allIdlePeriods) {
                    if (
                        this.$moment(this.timestopInfos.start, 'YYYY-MM-DD').isBetween(
                            idlePeriod.start,
                            idlePeriod.end,
                            'day',
                            '[]'
                        ) ||
                        this.$moment(this.timestopInfos.end, 'YYYY-MM-DD').isBetween(
                            idlePeriod.start,
                            idlePeriod.end,
                            'day',
                            '[]'
                        )
                    ) {
                        this.checkingCheckins = false
                        return alert('Du hast bereits einen Timestop in deiner ausgewählten Zeitspanne.')
                    }
                }
                this.e1 = 4
            }
            this.checkingCheckins = false
        },
        async confirmUserInfos() {
            if (
                this.customerInfos.birthday === '--' ||
                this.customerInfos.gender === 'a' ||
                !this.customerInfos.gender ||
                !this.customerInfos.firstName ||
                !this.customerInfos.lastName ||
                !this.customerInfos.phone ||
                !this.customerInfos.birthday ||
                !this.customerInfos.street ||
                !this.customerInfos.city ||
                !this.customerInfos.zip
            ) {
                return this.$toast.error('Bitte fülle alle Felder aus')
            }

            if (confirm('Ich bestätige das meine Daten korrekt sind')) {
                this.e1 = 2
            }
        },
        deleteImageTimestops(index) {
            this.timestopInfos.gallery.splice(index, 1)
            this.$forceUpdate()
        },

        uploadImagesTimestops(files) {
            for (let file of files) {
                const reader = new FileReader()
                reader.onload = (e) => {
                    const formData = new FormData()
                    formData.append('file', file)

                    api.uploadTimestopImage(formData)
                        .then((response) => {
                            const uploadedImageName = response.name
                            if (!this.timestopInfos.gallery) this.timestopInfos.gallery = []
                            this.timestopInfos.gallery.push({
                                title: file.name,
                                src: uploadedImageName,
                                dataUrl: e.target.result,
                            })

                            this.$forceUpdate()
                        })
                        .catch((error) => {
                            this.$toast.error(
                                'Fehler beim hinzufügen deiner Datei. Achte darauf, dass nur gängige Bildformate und PDFs erlaubt sind'
                            )
                            console.log('Upload failed', error)
                        })
                }
                reader.readAsDataURL(file)
            }
        },

        resetSignature() {
            this.signature = false
            this.customerInfos.signature = null
        },
        daysInTimeSpan(timeSpan) {
            const start = this.$moment(timeSpan.TimeSpan.Start, 'YYYY-MM-DD')
            const end = this.$moment(timeSpan.TimeSpan.End, 'YYYY-MM-DD')
            return end.diff(start, 'days')
        },
        async saveEntry() {
            let propStudio = this.storeStudios.find(
                (stud) => stud.tacPropertyId === this.contractInformations.PropertyID.UniqueID[0].value
            )
            if (!propStudio || !propStudio.tacResortId) {
                let apiStudios = await api.fetchStudios().catch((e) => (this.error = true))
                propStudio = apiStudios.find(
                    (stud) => stud.tacPropertyId === this.contractInformations.PropertyID.UniqueID[0].value
                )
            }
            let data = {
                checkOnly: false,
                TimeSpan: {
                    Start: this.timestopInfos.start,
                    End: this.timestopInfos.end,
                },
                Duration: Number(
                    this.$moment(this.timestopInfos.end, 'YYYY-MM-DD').diff(
                        this.$moment(this.timestopInfos.start, 'YYYY-MM-DD'),
                        'days'
                    ) + 1
                ),
                Comment: 'Kommentar',
                TimeUnit: 'DAY',
                ReasonId: this.timestopInfos.tacReason,
                Reason: this.tacReasons.Reasons.Reason.find(
                    (reason) => reason.ReasonID.UniqueID === this.timestopInfos.tacReason
                ).ReasonCode,

                ResortID: propStudio.tacResortId,
                IdlePeriodFee: {
                    currencyCode: 'CHF',
                    value: Number(this.getPriceWithoutAttest),
                },
                IdlePeriodType: 'EXTEND_USAGETIME',
            }
            // let test = await api.createTACTimestop(data)
            this.$refs.signatureConditions.saveSignature()
            let entry = {
                withAttest: this.withAttest,
                studio: propStudio._id,
                customerInfos: this.customerInfos,
                timestopInfos: this.timestopInfos,
                profileID: this.profileID,
                //timestopFormId: this.timestopFormId,
                status: 'open',
                tacData: data,
                fee: {
                    withAttest: this.getPriceWithAttest,
                    withoutAttest: this.getPriceWithoutAttest,
                },
            }

            try {
                await api.saveTimestopEntry(entry)
                this.$toast.success('Antrag erfolgreich gestellt')
                this.$emit('onSuccess')
            } catch (e) {
                console.log(e)
                this.$toast.error('Fehler beim abspeichern des Antrags')
            }
        },

        saveSignature(e) {
            this.customerInfos.signature = e
        },
        closeForm() {
            this.$emit('onClose')
        },
    },
    props: {
        showPrices: {
            type: Boolean,
            default: false,
        },
        viewOnly: {
            type: Boolean,
            default: false,
        },
        newEntry: {
            type: Boolean,
            default: false,
        },
        formData: {
            type: Object,
        },
        visible: {
            type: Boolean,
        },
    },
    computed: {
        ...mapState('studioStore', ['storeStudios']),

        getPriceWithAttest() {
            let priceForAbo = this.selectedContract.aboPrice
            let withAttest = Math.round((priceForAbo / this.dividePriceBy) * 20) / 20
            return this.getPriceWithoutAttest < withAttest ? this.getPriceWithoutAttest : withAttest
        },
        getPriceWithoutAttest() {
            let price = this.selectedContract.aboPrice

            let divideBy = this.dividePriceBy / 12

            let aboInYears = this.$moment(this.selectedContract.end, 'YYYY-MM-DD').diff(
                this.$moment(this.selectedContract.start, 'YYYY-MM-DD'),
                'years',
                true
            )

            let aboDuration =
                this.$moment(this.selectedContract.end, 'YYYY-MM-DD').diff(
                    this.$moment(this.selectedContract.start, 'YYYY-MM-DD'),
                    'days'
                ) + 1

            let timeStopDuration =
                this.$moment(this.timestopInfos.end, 'YYYY-MM-DD').diff(
                    this.$moment(this.timestopInfos.start, 'YYYY-MM-DD'),
                    'days'
                ) + 1
            return Math.round((((price / aboDuration) * timeStopDuration) / divideBy) * 20) / 20
        },
        latestPossibleDate() {
            let activeContract = this.selectedContract
            let latestDate = activeContract.end
            return this.$moment(latestDate, 'YYYY-MM-DD')
        },
        calcEndPickerMaxDate() {
            let calculatedMax = this.$moment(this.timestopInfos.start, 'YYYY-MM-DD').add('months', 6)
            if (calculatedMax.isAfter(this.latestPossibleDate)) {
                calculatedMax = this.latestPossibleDate
            }
            return calculatedMax.format()
        },
        calcStartPickerMaxDate() {
            let latestPossible = this.$moment(this.latestPossibleDate)
            let calculatedMax = this.$moment(this.selectedContract.end, 'YYYY-MM-DD').subtract('months', 1)

            if (calculatedMax.isBefore(this.$moment(latestPossible).subtract('months', 1))) {
                calculatedMax = latestPossible.subtract('months', 1)
            }
            return calculatedMax.format()
        },

        infoPageRules() {
            // Überprüfen, ob die Start- und Enddaten vorhanden und korrekt sind
            if (
                !this.timestopInfos.start ||
                !this.timestopInfos.end ||
                new Date(this.timestopInfos.start) > new Date(this.timestopInfos.end)
            ) {
                return true
            }

            // Überprüfen, ob die restlichen Felder ausgefüllt sind
            if (!this.timestopInfos.duration || !this.timestopInfos.durationSelection || !this.timestopInfos.fee) {
                return true
            }

            // Wenn bis hierher alles in Ordnung ist, ist die Validierung erfolgreich
            return false
        },
    },
    watch: {
        async visible() {
            if (this.visible) {
                this.e1 = 1
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.dot {
    height: 12px;
    width: 12px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    margin: 0 4px;
    cursor: pointer;
}

.dot.active {
    background-color: #717171;
}
</style>